<template>
  <div
    style="min-width: 80px;"
    :class="`${getBgColor} bg-opacity-50 ${getTextColor} fd-w-full fd-inline fd-py-1.5 fd-px-5 fd-text-xs fd-rounded-full fd-font-normal`"
  >
    {{ label || status }}
  </div>
</template>

<script>
export default {
  name: "StatusField",
  props: {
    status: {
      type: String,
      required: true
    },
    label: {
      type: String
    }
  },
  data() {
    return {
      colors: {
        sold: {
          text: 'fd-text-red-600',
          background: 'fd-bg-red-100'
        },
        available: {
          text: 'fd-text-green-600',
          background: 'fd-bg-green-100'
        },
        done: {
          text: 'fd-text-green-600',
          background: 'fd-bg-green-100'
        },
        reserved: {
          text: 'fd-text-yellow-600',
          background: 'fd-bg-yellow-100'
        },
        pending: {
          text: 'fd-text-yellow-600',
          background: 'fd-bg-yellow-100'
        },
        rejected: {
          text: 'fd-text-red-600',
          background: 'fd-bg-red-100'
        },
        accepted: {
          text: 'fd-text-green-600',
          background: 'fd-bg-green-100'
        },
        active: {
          text: 'fd-text-green-600',
          background: 'fd-bg-green-100'
        },
        cancelled: {
          text: 'fd-text-gray-600',
          background: 'fd-bg-gray-200'
        },
        cancel: {
          text: 'fd-text-gray-600',
          background: 'fd-bg-gray-200'
        },
      }
    }
  },
  computed: {
    getTextColor() {
      return this.colors[this.status.toLowerCase()].text
    },
    getBgColor() {
      return this.colors[this.status.toLowerCase()].background
    }
  }
}
</script>

<style scoped>

</style>