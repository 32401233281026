<template>
  <div
    class="table fd-w-full fd-relative fd-bg-white fd-rounded-lg"
    :class="[fields.length > 6 ? 'change' : '']"
    style="height: auto; min-height: 500px; padding-bottom: 6rem;"
  >
    <div v-if="showList && showTotal" class="fd-w-full fd-flex fd-items-center fd-justify-start" :class="[hasFilter ? '' : 'fd-mb-8']">
      <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('table.listOf') + ' ' + name }}</span>
    </div>

    <div class="fd-w-full fd-block xl:fd-flex fd-items-center fd-mt-3 xl:fd-my-0">
      <span v-if="showTotal" class="fd-text-sm fd-font-medium fd-text-theme-2">{{ $t('table.total') + ` ${name} ${total}` }}</span>
      <div v-if="showList && !showTotal" class="fd-w-1/2 fd-flex fd-items-center fd-justify-start" :class="[hasFilter ? '' : '']">
        <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('table.listOf') + ' ' + name }}</span>
      </div>

      <div v-if="hasSearch" class="ml-auto fd-flex fd-items-center fd-my-6 xl:fd-my-0" :class="[hasFilter ? 'fd-mr-4' : 'fd-mr-0']">
        <span class="fd-text-sm fd-mr-4" style="color: #6E6B7B">{{ $t('global.search') }}</span>
        <b-form-input v-model="search" type="text" debounce="500" class=""></b-form-input>
      </div>

      <div v-if="hasFilter" :class="[hasSearch ? '' : 'ml-auto']">
        <b-button @click.prevent="toggleFilterSidebar" variant="outline-primary">
          <div class="fd-flex fd-items-center">
            <SvgLoader :name="'filterIcon'" />
            <span class="fd-block fd-ml-2">{{ $t('global.filters') }}</span>
          </div>
        </b-button>
      </div>
    </div>

    <div v-if="hasFilter" class="fd-flex fd-items-center" :class="[getSelectedFilters.length ? 'fd-mb-6' : '']">
        <span
          v-for="(filter, index) in getSelectedFilters"
          :key="index"
          class="fd-flex fd-items-center bg-primary fd-text-white fd-rounded-lg fd-px-2 fd-py-1 fd-mr-2"
        >
          <span>{{ filter }}</span>
          <span class="fd-block fd-px-1 fd-ml-1 fd-cursor-pointer" @click="removeFilter(index)">
            <svg width="16" height="17" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.025 13.475C14.325 13.775 14.325 14.225 14.025 14.525C13.875 14.675 13.725 14.75 13.5 14.75C13.275 14.75 13.125 14.675 12.975 14.525L9 10.55L5.025 14.525C4.875 14.675 4.725 14.75 4.5 14.75C4.275 14.75 4.125 14.675 3.975 14.525C3.675 14.225 3.675 13.775 3.975 13.475L7.95 9.5L3.975 5.525C3.675 5.225 3.675 4.775 3.975 4.475C4.275 4.175 4.725 4.175 5.025 4.475L9 8.45L12.975 4.475C13.275 4.175 13.725 4.175 14.025 4.475C14.325 4.775 14.325 5.225 14.025 5.525L10.05 9.5L14.025 13.475Z" fill="black"/>
              <mask id="mask0_233_18042" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="4" width="12" height="11">
                <path d="M14.025 13.475C14.325 13.775 14.325 14.225 14.025 14.525C13.875 14.675 13.725 14.75 13.5 14.75C13.275 14.75 13.125 14.675 12.975 14.525L9 10.55L5.025 14.525C4.875 14.675 4.725 14.75 4.5 14.75C4.275 14.75 4.125 14.675 3.975 14.525C3.675 14.225 3.675 13.775 3.975 13.475L7.95 9.5L3.975 5.525C3.675 5.225 3.675 4.775 3.975 4.475C4.275 4.175 4.725 4.175 5.025 4.475L9 8.45L12.975 4.475C13.275 4.175 13.725 4.175 14.025 4.475C14.325 4.775 14.325 5.225 14.025 5.525L10.05 9.5L14.025 13.475Z" fill="white"/>
              </mask>
              <g mask="url(#mask0_233_18042)">
                <rect y="0.5" width="18" height="18" fill="#ffffff"/>
              </g>
            </svg>
          </span>
        </span>
    </div>

    <div :class="'fd-mt-6'">
      <b-table
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :busy="isBusy"
        show-empty
        responsive
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner variant="primary" class="align-middle"></b-spinner>
            <strong>{{ $t('global.loading') }}</strong>
          </div>
        </template>

        <template #head(actions)="data">
          <div class="">
            <span>{{ data.label.toUpperCase() }}</span>
          </div>
        </template>

        <template #cell(name)="row">
          <div
            @click="checkClickable(row)"
            class="fd-flex fd-items-center"
            :class="[('clickable' in row.field && row.field.clickable) ? 'fd-cursor-pointer' : '']"
          >
            <img
              v-if="!hasImageColumn && 'thumbnail' in row.item"
              style="width: 30px; height: 30px; object-fit: cover; border-radius: 6px"
              :src="row.item.thumbnail ? row.item.thumbnail : require('@/assets/images/pages/project/default-image.png')"
              alt=""
              @error="imageDefaultPlaceholder($event, row)"
            >
            <span class="fd-block fd-ml-2">{{ row.item.name }}</span>
          </div>
        </template>
        <template #cell(ana_agent)="row">
          <div class="fd-flex fd-items-center">
            <img
              style="width: 30px; height: 30px; object-fit: cover; border-radius: 6px;"
              :src="row.item.ana_agent.thumbnail ? row.item.ana_agent.thumbnail : require('@/assets/images/pages/project/user-default.jpeg')"
              @error="imagePlaceholder"
              alt=""
            >
            <span class="fd-block fd-ml-2">{{ row.item.ana_agent.first_name + ' ' + row.item.ana_agent.last_name }}</span>
          </div>
        </template>
        <template #cell(rs_agent)="row">
          <div v-if="'thumbnail' in row.item.rs_agent" class="fd-flex fd-items-center">
            <img
              style="width: 30px; height: 30px; object-fit: cover; border-radius: 6px;"
              :src="row.item.rs_agent.thumbnail ? row.item.rs_agent.thumbnail : require('@/assets/images/pages/project/user-default.jpeg')"
              alt=""
              @error="imagePlaceholder"
            >
            <span class="fd-block fd-ml-2">{{ row.item.rs_agent.first_name + ' ' + row.item.rs_agent.last_name }}</span>
          </div>
          <div v-else>
            <span :id="`rsCount-${row.item.id}`" class="fd-block fd-w-8 fd-text-center fd-rounded-full fd-mx-3 fd-my-1" style="background-color: #F1F1F1; margin: 0 !important;">{{ row.item.rs.length }}</span>
            <b-tooltip :target="`rsCount-${row.item.id}`" placement="bottom" boundary="document">
              <span class="fd-text-xs fd-font-normal">{{ row.item.rs.join(' - ') }}</span>
            </b-tooltip>
          </div>
        </template>

        <template #cell(rss)="row">
          <div>
            <span :id="`rsCount-${row.item.id}`" class="fd-block fd-w-8 fd-text-center fd-rounded-full fd-mx-3 fd-my-1" style="background-color: #F1F1F1; margin: 0 !important;">{{ row.item.rss.length }}</span>
            <b-tooltip :target="`rsCount-${row.item.id}`" placement="bottom" boundary="document">
              <span class="fd-text-xs fd-font-normal">{{ row.item.rss.join(' - ') }}</span>
            </b-tooltip>
          </div>
        </template>

        <template #cell(languages)="row">
          <div>
            <span :id="`languageCount-${row.item.id}`" class="fd-block fd-w-8 fd-text-center fd-rounded-full fd-mx-3 fd-my-1" style="background-color: #F1F1F1; margin: 0 !important;">{{ row.item.languages.length }}</span>
            <b-tooltip :target="`languageCount-${row.item.id}`" placement="bottom" boundary="document">
              <span class="fd-text-xs fd-font-normal">{{ row.item.languages.join(' - ') }}</span>
            </b-tooltip>
          </div>
        </template>

        <template #cell(projects)="row">
          <div>
            <span :id="`projectCount-${row.item.id}`" class="fd-block fd-w-8 fd-text-center fd-rounded-full fd-mx-3 fd-my-1" style="background-color: #F1F1F1; margin: 0 !important;">{{ row.item.projects.length }}</span>
            <b-tooltip :target="`projectCount-${row.item.id}`" placement="bottom" boundary="document">
              <span class="fd-text-xs fd-font-normal">{{ row.item.projects.join(' - ') }}</span>
            </b-tooltip>
          </div>
        </template>

        <template v-slot:cell(avatar)="row">
          <img
            :src="row.item.avatar ? row.item.avatar : require('@/assets/images/pages/project/user-default.jpeg')"
            alt="avatar" style="width: 30px; height: 30px; object-fit: cover; border-radius: 6px;"
            @error="imagePlaceholder"
          />
        </template>

        <template v-slot:cell(property)="row">
          <span :class="[row.field.hasBackground ? 'property' : '']">
            {{ row.item.property }}
          </span>
        </template>
        <template v-slot:cell(lead.template)="row">
          <span :class="[row.field.hasBackground ? 'property' : '']">
            {{ row.item.lead.template }}
          </span>
        </template>

        <template v-slot:cell(status)="row">
          <StatusField :status="row.item.status" />
        </template>

        <template v-slot:cell(contact_info)="row">
          <slot name="contactInfo" :row="row"></slot>
        </template>
        <template v-slot:cell(invited_at)="row"><slot name="invitedAt" :row="row"></slot></template>
        <template v-slot:cell(referrer)="row"><slot name="referrer" :row="row"></slot></template>

        <template #cell(actions)="row">
          <div v-if="!checkByStatus(row)">
            <Actions
              v-if="!hasActionsCustom"
              :hasEditIcon="hasEditIcon"
              :hasRemoveIcon="hasRemoveIcon"
              :hasViewIcon="hasViewIcon"
              :hasReserveIcon="hasReserveIcon"
              :hasSoldIcon="hasSoldIcon"
              :hasDoneIcon="hasDoneIcon"
              :hasCloseIcon="hasCloseIcon"
              :hasTickIcon="hasTickIcon"
              @showRemoveComponent="$emit('showRemoveComponent', row.item.id)"
              @showEditComponent="$emit('showEditComponent', row.item.id)"
              @viewComponent="$emit('viewComponent', row.item.id)"
              @showSoldComponent="$emit('showSoldComponent', row.item)"
              @showReserveComponent="$emit('showReserveComponent', row.item)"
              @doneComponent="$emit('doneComponent', row.item)"
              @closeComponent="$emit('closeComponent', row.item)"
              @tickComponent="$emit('tickComponent', row.item)"
            />
            <slot v-if="hasActionsCustom" name="actionsCustom" :row="row"></slot>
          </div>

          <div v-if="checkByStatus(row)">
            <Actions
              :hasEditIcon="row.item.status.toLowerCase() === 'pending' || row.item.status.toLowerCase() === 'accepted'"
              :hasRemoveIcon="row.item.status.toLowerCase() !== 'done'"
              :hasViewIcon="hasViewIcon"
              :hasDoneIcon="row.item.status.toLowerCase() === 'accepted'"
              :hasCloseIcon="row.item.status.toLowerCase() === 'pending'"
              :hasTickIcon="row.item.status.toLowerCase() === 'pending'"
              @showRemoveComponent="$emit('showRemoveComponent', row.item.id)"
              @showEditComponent="$emit('showEditComponent', row.item.id)"
              @viewComponent="$emit('viewComponent', row.item.id)"
              @doneComponent="$emit('doneComponent', row.item.id)"
              @closeComponent="$emit('closeComponent', row.item.id)"
              @tickComponent="$emit('tickComponent', row.item.id)"
            />
          </div>

        </template>
      </b-table>
    </div>
    <div
      class="fd-w-full fd-flex fd-items-end"
      :class="[hasFooterSlot ? 'fd-justify-between fd-absolute fd-left-0 fd-bottom-0' : 'fd-justify-center fd-absolute fd-left-50-x fd-bottom-0']"
    >
      <div v-if="hasPagination">
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          hide-goto-end-buttons
          prev-class="prev-item"
          next-class="next-item"
          class="my-1"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <slot name="footer-tab"></slot>
    </div>

    <div>
      <b-sidebar
        :id="filterId"
        no-header
        bg-variant="white"
        shadow right lazy backdrop
        :backdrop-variant="'dark'"
      >
        <template #default="{ hide }">
          <div class="fd-relative fd-flex fd-items-center fd-justify-between fd-p-3" style="background-color: #E9EDEB;">
            <div class="fd-flex fd-items-center">
              <SvgLoader :name="'filterIcon'" />
              <span class="fd-block fd-ml-2">{{ $t('global.filters') }}</span>
            </div>
            <div @click="toggleFilterSidebar" class="fd-cursor-pointer">
              <SvgLoader :name="'close'" />
            </div>
          </div>

          <slot name="filters"></slot>

          <div class="fd-w-full fd-absolute fd-left-0 fd-bottom-0 fd-p-3 fd-bg-white" style="z-index: 99">
            <b-button @click="$emit('applyFilter')" variant="primary" class="fd-w-full fd-mb-2">{{ $t('global.save') }}</b-button>
            <b-button @click="clearAllFilters" variant="outline-danger" class="fd-w-full">{{ $t('table.clearAllFilters') }}</b-button>
          </div>
        </template>

      </b-sidebar>
    </div>

  </div>
</template>

<script>
import { BTable, BPagination, BSpinner, BDropdown, BDropdownItem, BFormInput, BButton, BSidebar, BTooltip } from 'bootstrap-vue'
import Actions from "@/views/components/global/Actions";
import StatusField from "@/views/components/global/StatusField";

export default {
  name: "Table",
  components: {StatusField, Actions, BTable, BPagination, BSpinner, BDropdown, BDropdownItem, BFormInput, BButton, BSidebar, BTooltip },
  data() {
    return {
      filter: null,
      dataLoaded: false,
      selectedMenu: 0,
      currentPage: 1,
      selectedFilters: [],
      search: ''
    }
  },
  props: {
    filterId: {
      type: String,
      default: 'filter-sidebar'
    },
    name: {
      type: String,
      default: ''
    },
    total: {
      type: Number | String,
      default: 0
    },
    fields: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    perPage: {
      type: Number,
      default: 10
    },
    filters: {
      type: Array,
      default: () => []
    },
    isBusy: {
      type: Boolean,
      default: false
    },
    hasFilter: {
      type: Boolean,
      default: false
    },
    hasSearch: {
      type: Boolean,
      default: false
    },
    hasImageColumn: {
      type: Boolean,
      default: false
    },
    hasEditIcon: {
      type: Boolean,
      default: true
    },
    hasRemoveIcon: {
      type: Boolean,
      default: true
    },
    hasViewIcon: {
      type: Boolean,
      default: false
    },
    hasReserveIcon: {
      type: Boolean,
      default: false
    },
    hasSoldIcon: {
      type: Boolean,
      default: false
    },
    hasDoneIcon: {
      type: Boolean,
      default: false
    },
    hasCloseIcon: {
      type: Boolean,
      default: false
    },
    hasTickIcon: {
      type: Boolean,
      default: false
    },
    showTotal: {
      type: Boolean,
      default: true
    },
    showList: {
      type: Boolean,
      default: true
    },
    hasPagination: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.$nextTick(() => this.dataLoaded = true)
  },
  watch: {
    currentPage: {
      handler(newVal) {
        if (!this.dataLoaded) return
        return this.$emit('changedPage', newVal)
      }
    },
    selectedFilters: {
      handler(newVal) {
        if (!this.dataLoaded) return
        return this.$emit('setFilters', newVal)
      }
    },
    search: {
      handler(newVal) {
        return this.$emit('setSearch', newVal)
      }
    }
  },
  computed: {
    hasActionsCustom() {
      return !! this.$scopedSlots['actionsCustom']
    },
    getSelectedFilters() {
      return this.selectedFilters
    },
    hasFooterSlot() {
      return !!this.$slots['footer-tab'];
    }
  },
  methods: {
    info(item, index, button) {
      console.log(item, index, button)
    },
    setFilters(filter) {
      if (!this.selectedFilters.includes(filter)) {
        this.selectedFilters.push(filter)
      }
    },
    removeFilter(index) {
      this.selectedFilters.splice(index, 1);
    },
    clearAllFilters() {
      this.toggleFilterSidebar()
      return this.$emit('clearAllFilters')
    },
    toggleFilterSidebar() {
      this.$root.$emit('bv::toggle::collapse', this.filterId)
    },
    checkClickable(row) {
      if ('clickable' in row.field && row.field.clickable) {
        return this.$emit('showEditComponent', row.item.id)
      }
    },
    imageDefaultPlaceholder(e, row) {
      if (('showUserPlaceholder' in row.field && row.field.showUserPlaceholder)) {
        e.target.src = require('@/assets/images/pages/project/user-default.jpeg')
      } else {
        e.target.src = require('@/assets/images/pages/project/default-image.png')
      }
    },
    imagePlaceholder(e) {
      e.target.src = require('@/assets/images/pages/project/user-default.jpeg')
    },
    checkByStatus(row) {
      if (! ('checkByStatus' in row.field)) return false
      return !!row.field.checkByStatus;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep table {
  border: 1px solid #BEC9C2 !important;
  min-height: 100%;

  thead {
    background-color: #E9EDEB !important;
  }

  th {
    border-top: 1px solid #BEC9C2 !important;
    border-bottom: 1px solid #BEC9C2 !important;
  }

  & th {
    padding: 0.72rem 0 0.72rem 1rem !important;
    &:last-child {
      padding-right: 1rem !important;
    }
  }
  & td {
    padding: 0.72rem 0 0.72rem 1rem !important;
    &:last-child {
      padding-right: 1rem !important;
    }
  }
}
.table ::v-deep button#dropdown-right__BV_toggle_.btn.dropdown-toggle.btn-secondary {
  background: #ffffff !important;
  border: none !important;
  color: #284A33 !important;
  box-shadow: none !important;
  //padding-bottom: 0 !important;
  height: 38px !important;
  font-size: 14px !important;
}
.table {
  ::v-deep #dropdown-right.dropdown.b-dropdown {
    border-radius: 5px !important;
  }
  ::v-deep input {
    border-radius: 5px !important;
  }
}

.table ::v-deep table.table thead tr th {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.table ::v-deep table.table tbody tr td {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.table {
  ::v-deep .btn {
    padding-top: .650rem !important;
    padding-bottom: .650rem !important;
  }
  .property {
    display: inline-block;
    min-width: 62px !important;
    text-align: center;
    background-color: #F1F1F1;
    padding: 5px 0;
    border-radius: 31px;
  }
}

.table ::v-deep table.table thead th, .table ::v-deep table.table tfoot th {
  font-weight: 600 !important;
}
.table.change ::v-deep table.table thead th, .table ::v-deep table.table tfoot th {
  font-size: 12px !important;
}
.table .fd-left-50-x {
  left: 50%;
  transform: translateX(-50%);
}
</style>